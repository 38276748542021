import Link from 'next/link';
import { Button } from "@/components/ui/button";

export default function Custom404() {
  return (
    <div className="flex flex-col items-center justify-center h-96 py-2 space-y-4">
      <h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">
        404 - Page Not Found
      </h1>
      <p className="text-xl text-muted-foreground">
        The page you are looking for does not exist.
      </p>
      <Button asChild variant="default">
        <Link href="/">
          Go back to the homepage
        </Link>
      </Button>
    </div>
  );
}
